<template>
  <div class="">
    <div class="pt-8">
      <b-steps
        v-model="activeStep"
        :animated="isAnimated"
        :rounded="isRounded"
        :has-navigation="hasNavigation"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        :label-position="labelPosition"
        :mobile-mode="mobileMode"
        icon-pack="fas"
      >
        <b-step-item step="1" label="Account" :clickable="isStepsClickable">
          <div class="flex justify-center pt-7">
            <div class="w-1/3 height">
              <b-field label="Full Name" class="mb-0">
                <b-input
                  type="text"
                  v-model="editStudentDetail.name"
                  maxlength="35"
                  required
                ></b-input>
              </b-field>

              <b-field label="Gender" class="mb-5">
                <b-radio
                  v-model="editStudentDetail.gender"
                  name="gender"
                  native-value="Male"
                >
                  Male
                </b-radio>
                <b-radio
                  v-model="editStudentDetail.gender"
                  name="gender"
                  native-value="Female"
                >
                  Female
                </b-radio>
                <b-radio
                  v-model="editStudentDetail.gender"
                  name="gender"
                  native-value="Others"
                >
                  Others
                </b-radio>
              </b-field>
              <b-field label="Date Of Birth">
                <b-input
                  type="text"
                  v-model="editStudentDetail.dateofbirth"
                  maxlength="10"
                  required
                ></b-input>
              </b-field>
              <b-field label="Father Name" class="mb-0">
                <b-input
                  type="text"
                  v-model="editStudentDetail.father_name"
                  maxlength="35"
                  required
                ></b-input>
              </b-field>
              <b-field label="Mother Name" class="mb-0">
                <b-input
                  type="text"
                  v-model="editStudentDetail.mother_name"
                  maxlength="35"
                  required
                ></b-input>
              </b-field>
            </div>
          </div>
        </b-step-item>

        <b-step-item
          step="2"
          label="Address"
          :clickable="isStepsClickable"
          :type="{ 'is-success': isProfileSuccess }"
        >
          <div class="flex justify-center pt-7">
            <div class="w-1/3 height">
              <b-field label="Address">
                <b-input
                  maxlength="200"
                  type="textarea"
                  v-model="editStudentDetail.address"
                  required
                ></b-input>
              </b-field>
              <b-field label="City" class="mb-0">
                <b-input
                  type="text"
                  v-model="editStudentDetail.city"
                  required
                  maxlength="30"
                ></b-input>
              </b-field>
              <b-field label="Pin Code" class="mb-0">
                <b-input
                  type="text"
                  v-model="editStudentDetail.pincode"
                  required
                  maxlength="6"
                ></b-input>
              </b-field>
              <b-field label="State" class="mb-0">
                <b-select
                  placeholder="Select State"
                  required
                  expanded
                  v-model="editStudentDetail.state"
                >
                  <option
                    v-for="(state, index) in states"
                    :key="index"
                    :value="state"
                    >{{ state }}</option
                  >
                </b-select>
              </b-field>
            </div>
          </div>
        </b-step-item>

        <b-step-item
          step="3"
          label="Contact & Eno."
          :clickable="isStepsClickable"
        >
          <div class="flex justify-center pt-7">
            <div class="w-1/3 height">
              <b-field class="file">
                <b-upload v-model="file" required expanded>
                  <a class="button is-primary is-fullwidth">
                    <b-icon icon="upload"></b-icon>
                    <span>{{ file.name || "Click to upload Image" }}</span>
                  </a>
                </b-upload>
              </b-field>
              <b-field label="Email" class="mb-0">
                <b-input
                  type="email"
                  v-model="editStudentDetail.email"
                  required
                  maxlength="50"
                ></b-input>
              </b-field>
              <b-field label="Phone Number" class="mb-0">
                <b-input
                  type="text"
                  v-model="editStudentDetail.phoneNumber"
                  required
                  maxlength="10"
                ></b-input>
              </b-field>
              <div class="grid md:grid-cols-2 grid-cols-1 gap-4">

              <b-field label="Enrollment Number" class="mb-0">
                <b-input
                  type="text"
                  v-model="editStudentDetail.enrollNumber"
                  required
                  maxlength="13"
                  minlength="13"
                  
                ></b-input>
              </b-field>

              <b-field label="Session" class="mb-0">
                <b-input
                  type="text"
                  v-model="editStudentDetail.session"
                  required
                ></b-input>
              </b-field>

              </div>
              <b-field label="Course" class="mb-0">
                <b-select
                  placeholder="Select Subject"
                  expanded
                  class="mr-2.5 mt-2.5"
                  v-model="editStudentDetail.course"
                  required
                >
                  <option
                    v-for="(course, index) in courses"
                    :key="index"
                    :value="course.name"
                    >{{ course.name }}</option
                  >
                </b-select>
              </b-field>
            </div>
          </div>
        </b-step-item>

        <b-step-item
          :step="4"
          label="Result"
          :clickable="isStepsClickable"
          disabled
        >
          <div class="flex justify-center pt-7">
            <div class=" height">
              <table>
                <tr v-for="(result, k) in editStudentDetail.results" :key="k">
                  <td scope="row" class="trashIconContainer">
                    <i
                      class="far fa-trash-alt mt-7 mr-4"
                      @click="deleteRow(k, result)"
                    ></i>
                  </td>
                  <td>
                    <!-- <b-input placeholder="subject" class="mr-2.5 mt-2.5" v-model="result.subject"></b-input> -->
                    <b-select
                      placeholder="Select Subject"
                      required
                      class="mr-2.5 mt-2.5"
                      v-model="result.subject"
                    >
                      <option
                        v-for="(subject, index) in subjects"
                        :key="index"
                        :value="subject.name"
                        >{{ subject.name }}</option
                      >
                    </b-select>
                  </td>
                  <td>
                    <b-input
                      placeholder="50"
                      class="mr-2.5 mt-2.5"
                      v-model="result.marksobtained"
                    ></b-input>
                  </td>
                  <td>
                    <b-input
                      placeholder="33"
                      class="mr-2.5 mt-2.5"
                      v-model="result.minmarks"
                    ></b-input>
                  </td>
                  <td>
                    <b-input
                      placeholder="100"
                      class="mr-2.5 mt-2.5"
                      v-model="result.maxmarks"
                    ></b-input>
                  </td>
                </tr>
              </table>

              <b-button
                type="is-success"
                class="mt-5 float-right ml-5 mr-2.5 justify-right w"
                icon-pack="fal"
                icon-left="edit"
                :loading="loading"
                @click="updateDetail"
                >Update Details</b-button
              >
              <b-button
                type="is-primary"
                class="mt-5 float-right justify-right"
                icon-pack="fas"
                icon-left="plus"
                @click="addNewRow"
                >Add Row</b-button
              >
            </div>
          </div>
        </b-step-item>
      </b-steps>

      <div class="absolute bottom-4 right-4 ">
        <b-message
          type="is-success"
          icon-pack="far"
          class=" w-96"
          has-icon
          v-model="isSuccess"
          :auto-close="autoClose"
        >
          <span class="text-xl pt-5">Data Successfully Added </span>
        </b-message>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as fb from "../firebase";
export default {
  name: "editStudentDetail",
  data() {
    return {
      isSuccess: false,
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      autoClose: true,
      hasNavigation: true,
      customNavigation: false,
      isProfileSuccess: false,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      labelPosition: "bottom",
      mobileMode: "minimalist",
      loading: false,
      file: {},
      routeID: this.$route.params.id,
    };
  },
  created() {
    console.log("Route : " + this.$route.params.id);
    const arr = this.$store.state.students.filter((x) => x.id == this.routeID);
    // this.$store.commit('viewStudent',arr[0])
    this.$store.commit("editStudent", arr[0]);
    // this.$store.dispatch("fetchSingleStudentDetail", { id: this.routeID });
  },
  methods: {
    addNewRow() {
      this.editStudentDetail.results.push({
        subject: "",
        marksobtained: "",
        minmarks: 33,
        maxmarks: 100,
        line_total: 0,
      });
      console.log("File Length : " + this.file.length);
      console.log("File : " + this.file.name);
    },
    deleteRow(index, result) {
      var idx = this.editStudentDetail.results.indexOf(result);
      console.log(idx, index);
      if (idx > -1) {
        this.editStudentDetail.results.splice(idx, 1);
      }
    },
    updateDetail() {
      this.loading = true;

      if (!this.file.name) {
        console.log("IF");
        fb.studentCollection.doc(this.editStudentDetail.id).set(
          {
            enrollNumber: this.editStudentDetail.enrollNumber,
            session:this.editStudentDetail.session,
            name: this.editStudentDetail.name,
            email: this.editStudentDetail.email,
            gender: this.editStudentDetail.gender,
            dateofbirth: this.editStudentDetail.dateofbirth,
            phoneNumber: this.editStudentDetail.phoneNumber,
            course: this.editStudentDetail.course,
            father_name: this.editStudentDetail.father_name,
            mother_name: this.editStudentDetail.mother_name,
            address: this.editStudentDetail.address,
            city: this.editStudentDetail.city,
            pincode: this.editStudentDetail.pincode,
            state: this.editStudentDetail.state,
            results: this.editStudentDetail.results,
          },
          { merge: true }
        );
        this.loading = false;
        this.isSuccess = true;
        console.log("IF END");
      } else {
        console.log("Else");

        const storageRefs = fb.storage.ref(`/profile/${this.file.name}`);
        storageRefs.put(this.file).then((snapshot) => {
          console.log("File Upload : " + snapshot);
          snapshot.ref.getDownloadURL().then((url) => {
            console.log("Image URL : " + url);
            fb.studentCollection.doc(this.editStudentDetail.id).set(
              {
                enrollNumber: this.editStudentDetail.enrollNumber,
                session:this.editStudentDetail.session,
                profile: {
                  primaryURL: url,
                  cdnURL: url.replace(
                    "https://firebasestorage.googleapis.com",
                    "https://ik.imagekit.io/ard/"
                  ),
                },
                name: this.editStudentDetail.name,
                email: this.editStudentDetail.email,
                gender: this.editStudentDetail.gender,
                dateofbirth: this.editStudentDetail.dateofbirth,
                phoneNumber: this.editStudentDetail.phoneNumber,
                course: this.editStudentDetail.course,
                father_name: this.editStudentDetail.father_name,
                mother_name: this.editStudentDetail.mother_name,
                address: this.editStudentDetail.address,
                city: this.editStudentDetail.city,
                pincode: this.editStudentDetail.pincode,
                state: this.editStudentDetail.state,
                results: this.editStudentDetail.results,
              },
              { merge: true }
            );
            this.loading = false;
            this.isSuccess = true;
            console.log("Data Saved");
          });
        });
        console.log("Else END");
      }
    },
  },
  computed: {
    ...mapState(["states", "subjects", "courses", "editStudentDetail"]),
  },
};
</script>

<style scoped>
.hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
</style>
